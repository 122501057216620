// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Sidebar/pict/best.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.allBest{
  width: 80%;
  margin-top: 100px;
  margin-bottom: 50px;
  display: flex;
  font-size: large;
  font-family: "myFont";
  background-color: #102a45;
}

.firstBest{
  display: flex;
  margin-left: 100px;
  align-items: end;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  height: 351px;
  width: 210px;
  text-align:center;
  justify-content: center;
}
.secondBest{  
  display: flex;
  margin-left: 200px;
  align-items: end;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  height: 351px;
  width: 210px;
  text-align:center;
  justify-content: center;
}
.thirdBest{
  display: flex;
  margin-left: 200px;
  align-items: end;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  height: 351px;
  width: 210px;
  text-align:center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/BestDisp.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,iBAAiB;EACjB,mBAAmB;EACnB,aAAa;EACb,gBAAgB;EAChB,qBAAqB;EACrB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,mDAAwC;EACxC,aAAa;EACb,YAAY;EACZ,iBAAiB;EACjB,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,mDAAwC;EACxC,aAAa;EACb,YAAY;EACZ,iBAAiB;EACjB,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,mDAAwC;EACxC,aAAa;EACb,YAAY;EACZ,iBAAiB;EACjB,uBAAuB;AACzB","sourcesContent":[".allBest{\n  width: 80%;\n  margin-top: 100px;\n  margin-bottom: 50px;\n  display: flex;\n  font-size: large;\n  font-family: \"myFont\";\n  background-color: #102a45;\n}\n\n.firstBest{\n  display: flex;\n  margin-left: 100px;\n  align-items: end;\n  background: url(./Sidebar/pict/best.jpg);\n  height: 351px;\n  width: 210px;\n  text-align:center;\n  justify-content: center;\n}\n.secondBest{  \n  display: flex;\n  margin-left: 200px;\n  align-items: end;\n  background: url(./Sidebar/pict/best.jpg);\n  height: 351px;\n  width: 210px;\n  text-align:center;\n  justify-content: center;\n}\n.thirdBest{\n  display: flex;\n  margin-left: 200px;\n  align-items: end;\n  background: url(./Sidebar/pict/best.jpg);\n  height: 351px;\n  width: 210px;\n  text-align:center;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
