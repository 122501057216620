// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  padding: 5px;
  text-align: left;
  background: #0d1f35;
  color: white;
  font-size: 10px;
  display: flex;
}
.headButton {
  margin: 10px 0px;
  min-width: 100px;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  border: 0;
  background: #0d1f35;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  padding: 20px 20px;
}`, "",{"version":3,"sources":["webpack://./src/Header/Header.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,uEAAuE;EACvE,SAAS;EACT,mBAAmB;EACnB,WAAW;EACX,eAAe;EACf,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".header {\n  padding: 5px;\n  text-align: left;\n  background: #0d1f35;\n  color: white;\n  font-size: 10px;\n  display: flex;\n}\n.headButton {\n  margin: 10px 0px;\n  min-width: 100px;\n  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;\n  border: 0;\n  background: #0d1f35;\n  color: #fff;\n  font-size: 1rem;\n  cursor: pointer;\n  padding: 20px 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
