import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import Header from './Header/Header'
import Sidebar from './Sidebar/Sidebar'
import Table from './Sidebar/Table'
import BestDisp from './BestDisp'



const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
      <Header/>
      <Sidebar/>
      <BestDisp/>
      <Table/>
      <div id='end' className='end'>
      "Министерство науки и высшего образования". "ФГБОУ ВО Кубанский Государственный университет" Факультет компьютерных технологий и прикладной математики Кафедра вычислительных технологий 02.03.02 Фундаментальная информатика и информационные технологии Анализ и проектирование информационных систем
      </div>
    
  </React.StrictMode>
);
