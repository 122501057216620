import React, {useEffect, useState} from 'react'
import './BestDisp.css'
const BestDisp = () =>{
    var contact = "";
    const [JsonData, setRecords] = useState([])
    const threeRand = {
        "pagination":{
        "count":3,
        "random":true},
      "filter": {
        "fcontact": {
          "hasContact": true
        },
        "fgit":{
            "hasGit": true
        }
        }
    }
    
    const [isLoading, setIsLoading] = useState(false);
      useEffect(()=>{ 
        if(!isLoading){
        fetch('https://students-list-back.k-lab.su/students/general/get-student-list', {
          method: 'POST',
          body: JSON.stringify(threeRand),
          headers: {
            'Content-type': 'application/json',
          },
        })
           .then((response) => response.json())
           .then((data) => {
              setRecords(data.info)
           })
           .catch((err) => {
              setIsLoading(true);
           
           });}},[isLoading]
      )

  
  
    const DisplayData=JsonData.map(
      (info)=>{
        if(info.fcontact.hasContact === false){
          contact = null;
      }
      else if (info.fcontact.ftelegram.hasTelegram === true){
          contact =(info.fcontact.ftelegram.telegram)
  
      }
      else if (info.fcontact.femail.hasEmail === true){
          contact =(info.fcontact.femail.email)
      }
      else if (info.fcontact.fphone.hasPhone === true){
          contact =(info.fcontact.fphone.phone)
      }
        return(
          <tr>
            {info.personalData.firstname}
            {info.personalData.lastname}
            <br/>
            {info.fgit.git}
            <br/>
            {contact}
         </tr>
        )
    }
  )
  return(
    <div id="allBest" className='allBest'>
      <div className='firstBest'>
      {DisplayData[0]}
      </div>
      <div className='secondBest'>
      {DisplayData[1]}
      </div>
      <div className='thirdBest'>
      {DisplayData[2]}
      </div>
    </div>
  )
  }
  export default BestDisp
