// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table {
	width: 100%;
	margin-bottom: 20px;
	border-collapse: collapse; 
}
.table th {
	font-weight: bold;
	padding: 5px;
	background: #efefef;
}

td:nth-child(4) {
	word-break: break-all;
   }

.table td {
	border: 1px solid #dddd;
	padding: 5px;
	text-align: center;
}

.table thead tr th:first-child {
	border-radius: 20px 0 0 0;
}
.table thead tr th:last-child {
	border-radius: 0 20px 0 0;
}

.red{
	background: #fe8e82;
}


.blockTable{
	width: 80%;
	background-color: #cfcfd1;
}

`, "",{"version":3,"sources":["webpack://./src/Sidebar/Table.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,mBAAmB;CACnB,yBAAyB;AAC1B;AACA;CACC,iBAAiB;CACjB,YAAY;CACZ,mBAAmB;AACpB;;AAEA;CACC,qBAAqB;GACnB;;AAEH;CACC,uBAAuB;CACvB,YAAY;CACZ,kBAAkB;AACnB;;AAEA;CACC,yBAAyB;AAC1B;AACA;CACC,yBAAyB;AAC1B;;AAEA;CACC,mBAAmB;AACpB;;;AAGA;CACC,UAAU;CACV,yBAAyB;AAC1B","sourcesContent":[".table {\n\twidth: 100%;\n\tmargin-bottom: 20px;\n\tborder-collapse: collapse; \n}\n.table th {\n\tfont-weight: bold;\n\tpadding: 5px;\n\tbackground: #efefef;\n}\n\ntd:nth-child(4) {\n\tword-break: break-all;\n   }\n\n.table td {\n\tborder: 1px solid #dddd;\n\tpadding: 5px;\n\ttext-align: center;\n}\n\n.table thead tr th:first-child {\n\tborder-radius: 20px 0 0 0;\n}\n.table thead tr th:last-child {\n\tborder-radius: 0 20px 0 0;\n}\n\n.red{\n\tbackground: #fe8e82;\n}\n\n\n.blockTable{\n\twidth: 80%;\n\tbackground-color: #cfcfd1;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
