import React, {useEffect, useState} from 'react'
import "./Table.css"
const Table = () =>{
    var contact = null;
    var count = 0;
    const [records, setRecords] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    useEffect(()=>{
        if(!isLoading){
        fetch('https://students-list-back.k-lab.su/students/general/get-student-list', {
            method: 'POST',
            headers: {
              'Content-type': 'application/json',
            },
          })
             .then((response) => response.json())
             .then((data) => {
                setRecords(data.info)
             })
             .catch((err) => {
                console.log(err.message);
                setIsLoading(true);
             });

        }},[isLoading])
        // Default options are marked with *


    
        const pageSize = 20;

        const [currentPage, setCurrentPage] = useState(1);
    
        const totalPages = Math.ceil(records.length / pageSize);
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;
    
      const handlePrevClick = () => {
        setCurrentPage(currentPage - 1);
      };
    
      const handleNextClick = () => {
        setCurrentPage(currentPage + 1);
      };



    const DisplayData=records.slice(startIndex, endIndex).map(
        (info)=>{
            if(info.fcontact.hasContact === false){
                contact = null
            }
            else if (info.fcontact.ftelegram.hasTelegram === true){
                contact =(info.fcontact.ftelegram.telegram)

            }
            else if (info.fcontact.femail.hasEmail === true){
                contact =(info.fcontact.femail.email)
            }
            else if (info.fcontact.fphone.hasPhone === true){
                contact =(info.fcontact.fphone.phone)
            }
            count += 1;
            return(
                <tr className={contact===null && info.fgit.git===undefined? 'red':'tr'}>
                    <td>{count}</td>
                    <td>{info.personalData.lastnameInitials}</td>
                    <td>{info.fgit.git}</td>
                    <td>{contact}</td>
                </tr>
            )
        }
    )




 
    if(count !== 0){
    return(
        
        <div className='blockTable'>
            <table id="table" className="table">
                <thead>
                    <tr>
                    <th>Номер</th>
                    <th>ФИО</th>
                    <th>Гит</th>
                    <th>Контакты</th>
                    </tr>
                </thead>
                <tbody>
                    {DisplayData}
                </tbody>
            </table>

            <div>
        <button onClick={handlePrevClick} disabled={currentPage === 1}>
          Prev
        </button>
        <span>{currentPage}</span>
        <button onClick={handleNextClick} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
        </div>
        
    )
    }
    else{
        return(
            <div className='noStud'>
                В систему пока не добавлены студенты.
            </div>
        )
    }
 }

 export default Table
 
