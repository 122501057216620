import React from "react";
import './Header.css';


const Header = () => {
  return (
    <header className="header" >
      <button className="headButton">
        Студенты
      </button>
    </header>
  );
}
export default Header