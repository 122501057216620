// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: sticky;
  background-color: #c28e21;
  width: 80%;
}

.allbutton{ 
  display: flex;
  right: 15px;
  flex-direction: column;
  width: 294px;
  height: 80%;
  flex-shrink: 3;
  position: fixed;
}



.welcome{
  background-color: #c28e21;
  color: white;
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  width: 100%;
}

.button { 
  margin: 10px 0px;
  min-width: 100px;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  border: 0;
  border-radius: 20px;
  background: black;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  padding: 20px 20px;
}
`, "",{"version":3,"sources":["webpack://./src/Sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,yBAAyB;EACzB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,WAAW;EACX,sBAAsB;EACtB,YAAY;EACZ,WAAW;EACX,cAAc;EACd,eAAe;AACjB;;;;AAIA;EACE,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,sHAAsH;EACtH,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,uEAAuE;EACvE,SAAS;EACT,mBAAmB;EACnB,iBAAiB;EACjB,WAAW;EACX,eAAe;EACf,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".sidebar {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  position: sticky;\n  background-color: #c28e21;\n  width: 80%;\n}\n\n.allbutton{ \n  display: flex;\n  right: 15px;\n  flex-direction: column;\n  width: 294px;\n  height: 80%;\n  flex-shrink: 3;\n  position: fixed;\n}\n\n\n\n.welcome{\n  background-color: #c28e21;\n  color: white;\n  font-size: 30px;\n  text-align: center;\n  font-weight: 600;\n  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;\n  width: 100%;\n}\n\n.button { \n  margin: 10px 0px;\n  min-width: 100px;\n  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;\n  border: 0;\n  border-radius: 20px;\n  background: black;\n  color: #fff;\n  font-size: 1rem;\n  cursor: pointer;\n  padding: 20px 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
